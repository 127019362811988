<template>
  <div class="w-full">
    <vs-table
      maxHeight="68vh"
      noDataText=""
      search
      pagination
      max-items="10"
      :data="empresas"
    >
      <template slot="header">
        <vs-row vs-w="12">
          <vs-col vs-w="6">
            <h4 style="color: #b4aa99">Empresas</h4>
          </vs-col>
          <vs-col vs-w="6">
            <ModalCadastroEmpresa @update="getEmpresas" />
          </vs-col>
        </vs-row>
      </template>
      <template slot="thead">
        <vs-th sort-key="nome_fantasia">Nome fantasia</vs-th>
        <vs-th sort-key="razao_social">Razão social</vs-th>
        <vs-th sort-key="cnpj">CNPJ</vs-th>
        <vs-th sort-key="cep">CEP</vs-th>
        <vs-th sort-key="endereco">Endereço</vs-th>
        <vs-th sort-key="inscricao_municipal">Insc. Municipal</vs-th>
        <vs-th sort-key="inscricao_estadual">Insc. Estadual</vs-th>
        <vs-th sort-key="data_fundacao">Data de fundação</vs-th>
        <vs-th>Ações</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr
          :state="tr.lixeira == 1 ? 'danger' : ''"
          :key="indextr"
          v-for="(tr, indextr) in data"
        >
          <vs-td :data="tr.nome_fantasia">{{ tr.nome_fantasia }}</vs-td>
          <vs-td :data="tr.razao_social">{{ tr.razao_social }}</vs-td>
          <vs-td :data="tr.cnpj">{{ tr.cnpj }}</vs-td>
          <vs-td :data="tr.cep">{{ tr.cep }}</vs-td>
          <vs-td :data="tr.endereco">{{ tr.endereco }}</vs-td>
          <vs-td :data="tr.inscricao_municipal">{{
            tr.inscricao_municipal
          }}</vs-td>
          <vs-td :data="tr.inscricao_estadual">{{
            tr.inscricao_estadual
          }}</vs-td>
          <vs-td :data="tr.data_fundacao">{{
            tr.data_fundacao | moment("DD/MM/YYYY")
          }}</vs-td>
          <vs-td class="actions">
            <vs-row
              vs-w="12"
              vs-type="flex"
              vs-align="center"
              vs-justify="center"
            >
              <vs-col
                vs-w="12"
                vs-type="flex"
                vs-align="flex-start"
                vs-justify="space-between"
              >
                <ModalEditarEmpresa @update="getEmpresas" :empresa="tr" />

                <modal-certificado-empresa
                  @update="getEmpresas"
                  :cnpj="tr.cnpj"
                />
              </vs-col>
            </vs-row>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>

<script>
import ModalCadastroEmpresa from "./cadastro.vue"
import ModalEditarEmpresa from "./editar.vue"
import ModalCertificadoEmpresa from "./certificado.vue"
import moment from "moment"

export default {
  data () {
    return {
      empresas: []
    }
  },
  methods: {
    async getEmpresas () {
      this.$vs.loading()
      try {
        this.empresas = await this.$http.get(`empresa`)
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        this.$vs.loading.close()
      }
    }
  },
  components: {
    ModalCadastroEmpresa,
    ModalEditarEmpresa,
    ModalCertificadoEmpresa,
    moment
  },
  mounted () {
    this.getEmpresas()
  }
};
</script>

<style>
.col {
  width: auto;
}
.actions {
  width: 60px !important;
}
</style>

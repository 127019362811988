<template>
  <div class="w-full">
    <SuccessButton
      class="mr-6"
      :disabled="!$atividades.permissoes.includes(14)"
      @func="errors.clear(), (modalCadastroEmpresa = true)"
    ></SuccessButton>
    <SideBar
      title="Cadastrar empresa"
      @hide="modalCadastroEmpresa = false"
      :active="modalCadastroEmpresa"
      size="large"
      @success="submitForm()"
    >
      <div slot="body">
        <VuePerfectScrollbar class="scroll-cadastrar-empresa" :settings="settings">
          <div
            class="w-full vs-con-loading__container"
            id="loadingCadastroEmpresa"
          >
            <vs-row
              class="my-5"
              vs-type="flex"
              vs-align="center"
              vs-justify="space-between"
            >
              <vx-card no-shadow card-border>
                <vs-row
                  vs-w="12"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-type="flex"
                  class="mb-4"
                >
                  <vs-col
                    vs-w="6"
                    vs-type="flex"
                    vs-align="center"
                    vs-justify="center"
                  >
                    <div class="mx-2 w-full">
                      <vs-input
                        class="inputx w-full"
                        label="Nome fantasia"
                        v-model="empresa.nome_fantasia"
                        v-validate="'required'"
                        name="nome_fantasia"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('nome_fantasia')"
                        >{{ $validators.empty }}</span
                      >
                    </div>
                  </vs-col>
                  <vs-col
                    vs-w="6"
                    vs-type="flex"
                    vs-align="center"
                    vs-justify="center"
                  >
                    <div class="mx-2 w-full">
                      <vs-input
                        class="inputx w-full"
                        label="Razão social"
                        v-model="empresa.razao_social"
                        v-validate="'required'"
                        name="razao_social"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('razao_social')"
                        >{{ $validators.empty }}</span
                      >
                    </div>
                  </vs-col>
                  <vs-col
                    vs-w="6"
                    vs-type="flex"
                    vs-align="center"
                    vs-justify="center"
                  >
                    <div class="mx-2 w-full">
                      <vs-input
                        class="inputx w-full mt-2"
                        label="CNPJ"
                        v-model="empresa.cnpj"
                        v-validate="'required|numeric|min:14|max:14'"
                        name="cnpj"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('cnpj')"
                        >{{ $validators.cnpj }}</span
                      >
                    </div>
                  </vs-col>
                  <vs-col
                    vs-w="6"
                    vs-type="flex"
                    vs-align="center"
                    vs-justify="center"
                  >
                    <div class="mx-2 w-full">
                      <vs-input
                        class="inputx w-full mt-2"
                        v-validate="'required'"
                        type="date"
                        name="data_fundacao"
                        label="Data de Fundação"
                        v-model="empresa.data_fundacao"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('data_fundacao')"
                        >{{ $validators.empty }}</span
                      >
                    </div>
                  </vs-col>

                  <vs-col
                    vs-w="6"
                    vs-type="flex"
                    vs-align="center"
                    vs-justify="center"
                  >
                    <div class="mx-2 w-full">
                      <vs-input
                        class="inputx w-full mt-2"
                        label="Inscrição estadual"
                        v-model="empresa.inscricao_estadual"
                        v-validate="'required|numeric'"
                        name="inscricao_estadual"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('inscricao_estadual')"
                        >{{ $validators.numeric }}</span
                      >
                    </div>
                  </vs-col>
                  <vs-col
                    vs-w="6"
                    vs-type="flex"
                    vs-align="center"
                    vs-justify="center"
                  >
                    <div class="mx-2 w-full">
                      <vs-input
                        class="inputx w-full mt-2"
                        label="Instrução municipal"
                        v-model="empresa.inscricao_municipal"
                        v-validate="'required|numeric'"
                        name="inscricao_municipal"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('inscricao_municipal')"
                        >{{ $validators.numeric }}</span
                      >
                    </div>
                  </vs-col>
                  <vs-col
                    vs-w="6"
                    vs-type="flex"
                    vs-align="center"
                    vs-justify="center"
                  >
                    <div class="mx-2 w-full">
                      <label
                        style="color: black; font-size: .85rem;"
                        class="mt-2"
                        >Regime Especial Tributação</label
                      ><br />
                      <el-select
                        filterable
                        clearable
                        name="regime"
                        class="w-full"
                        v-validate="'required'"
                        placeholder="Selecione..."
                        :popper-append-to-body="false"
                        v-model="empresa.regime_especial_tributacao"
                      >
                        <el-option
                          v-for="data in regime_especial_tributacao"
                          :value="data.id"
                          :label="data.nome"
                          :key="data.id"
                        >
                        </el-option>
                      </el-select>
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('regime')"
                        >{{ $validators.empty }}</span
                      >
                    </div>
                  </vs-col>
                  <vs-col
                    vs-w="6"
                    vs-type="flex"
                    vs-align="center"
                    vs-justify="center"
                  >
                    <div class="mx-2 w-full">
                      <vs-input
                        class="inputx mt-2 w-full"
                        label="Senha TLS"
                        v-model="empresa.senha"
                      />
                    </div>
                  </vs-col>
                </vs-row>
              </vx-card>
            </vs-row>
            <vs-row
              class="my-5"
              vs-type="flex"
              vs-align="center"
              vs-justify="center"
            >
              <vx-card no-shadow card-border>
                <vs-row
                  vs-w="12"
                  vs-justify="center"
                  vs-align="center"
                  vs-type="flex"
                  class="my-5 mb-4"
                >
                  <vs-col
                    vs-w="4"
                    class=""
                    vs-type="flex"
                    vs-align="center"
                    vs-justify="center"
                  >
                    <vs-checkbox
                      icon-pack="feather"
                      color="success"
                      icon="icon-check"
                      v-model="empresa.optante_simples_nacional"
                      >Optante Simples Nacional</vs-checkbox
                    >
                  </vs-col>

                  <vs-col
                    vs-w="4"
                    vs-type="flex"
                    vs-align="center"
                    vs-justify="center"
                  >
                    <vs-checkbox
                      icon-pack="feather"
                      icon="icon-check"
                      color="success"
                      v-model="empresa.incentivador_cultural"
                      >Incentivador Cultural</vs-checkbox
                    >
                  </vs-col>
                  <vs-col
                    vs-w="4"
                    vs-type="flex"
                    vs-align="center"
                    vs-justify="center"
                  >
                    <vs-checkbox
                      icon-pack="feather"
                      icon="icon-check"
                      color="success"
                      @change="$event.type == 'change' ? changeIsenta() : ''"
                      v-model="empresa.isenta_imposto"
                      >Isenta Imposto</vs-checkbox
                    >
                  </vs-col>
                </vs-row>
              </vx-card>
            </vs-row>

            <vs-row
              class="my-5"
              vs-type="flex"
              vs-align="center"
              vs-justify="space-between"
            >
              <vx-card no-shadow card-border>
                <vs-row
                  vs-w="12"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-type="flex"
                  class="mb-4"
                >
                  <vs-col
                    vs-w="5"
                    vs-type="flex"
                    vs-align="center"
                    vs-justify="center"
                  >
                    <div class="mx-2 w-full">
                      <vs-input
                        class="inputx w-full mt-2"
                        label="CEP"
                        v-model="empresa.cep"
                        v-validate="'required|numeric|min:8|max:8'"
                        name="cep"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('cep')"
                        >{{ $validators.cep }}</span
                      >
                    </div>
                  </vs-col>
                  <vs-col
                    vs-w="4"
                    vs-type="flex"
                    vs-align="center"
                    vs-justify="center"
                  >
                    <div class="mx-2 w-full">
                      <vs-input
                        class="inputx w-full mt-2"
                        label="Municipio"
                        v-model="empresa.municipio"
                        v-validate="'required'"
                        name="municipio"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('municipio')"
                        >{{ $validators.empty }}</span
                      >
                    </div>
                  </vs-col>
                  <vs-col
                    vs-w="3"
                    vs-type="flex"
                    vs-align="center"
                    vs-justify="center"
                  >
                    <div class="mx-2 w-full">
                      <vs-input
                        class="inputx w-full mt-2"
                        label="UF"
                        v-model="empresa.uf"
                        v-validate="'required'"
                        name="uf"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('uf')"
                        >{{ $validators.empty }}</span
                      >
                    </div>
                  </vs-col>
                  <vs-col
                    vs-w="12"
                    vs-type="flex"
                    vs-align="center"
                    vs-justify="center"
                  >
                    <div class="mx-2 w-full">
                      <vs-input
                        class="inputx w-full mt-2"
                        label="Endereço"
                        v-model="empresa.endereco"
                        v-validate="'required'"
                        name="endereco"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('endereco')"
                        >{{ $validators.empty }}</span
                      >
                    </div>
                  </vs-col>

                  <vs-col
                    vs-w="5"
                    vs-type="flex"
                    vs-align="center"
                    vs-justify="center"
                  >
                    <div class="mx-2 w-full">
                      <vs-input
                        class="inputx w-full mt-2"
                        label="Telefone"
                        v-model="empresa.telefone"
                        v-validate="'required|numeric|min:8|max:11'"
                        name="telefone"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('telefone')"
                        >{{ $validators.empty }}</span
                      >
                    </div>
                  </vs-col>
                  <vs-col
                    vs-w="7"
                    vs-type="flex"
                    vs-align="center"
                    vs-justify="center"
                  >
                    <div class="mx-2 w-full">
                      <vs-input
                        class="inputx w-full mt-2"
                        label="Website"
                        v-model="empresa.website"
                      />
                    </div>
                  </vs-col>
                </vs-row>
              </vx-card>
            </vs-row>
            <vs-divider class="p-0 mb-3 mt-0"
              ><b>Valor dos Impostos</b></vs-divider
            >
            <vs-row
              class="my-5"
              vs-type="flex"
              vs-align="center"
              vs-justify="space-between"
            >
              <vx-card no-shadow card-border>
                <vs-row
                  vs-w="12"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-type="flex"
                  class="mb-4"
                >
                  <vs-col
                    vs-w="3"
                    class="m-0"
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                  >
                    <vs-input
                      color="dark"
                      class="mx-2 w-full"
                      name="pis"
                      type="number"
                      min="0"
                      label="PIS"
                      icon="%"
                      icon-after="true"
                      v-model="empresa.valorPis"
                      :key="reRenderButton"
                      @change="reRenderButton += 1"
                      :disabled="empresa.isenta_imposto"
                    ></vs-input>
                  </vs-col>
                  <vs-col
                    vs-w="3"
                    class="m-0"
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                  >
                    <vs-input
                      color="dark"
                      class="mx-2 w-full"
                      name="cofins"
                      type="number"
                      min="0"
                      label="COFINS"
                      icon="%"
                      icon-after="true"
                      v-model="empresa.valorCofins"
                      :key="reRenderButton"
                      @change="reRenderButton += 1"
                      :disabled="empresa.isenta_imposto"
                    ></vs-input>
                  </vs-col>
                  <vs-col
                    vs-w="3"
                    class="m-0"
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                  >
                    <vs-input
                      color="dark"
                      class="mx-2 w-full"
                      name="csll"
                      type="number"
                      min="0"
                      label="CSLL"
                      icon="%"
                      icon-after="true"
                      v-model="empresa.valorCsll"
                      :key="reRenderButton"
                      @change="reRenderButton += 1"
                      :disabled="empresa.isenta_imposto"
                    ></vs-input>
                  </vs-col>
                  <vs-col
                    vs-w="3"
                    class="m-0"
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                  >
                    <vs-input
                      color="dark"
                      class="mx-2 w-full"
                      name="ir"
                      type="number"
                      min="0"
                      label="IR"
                      icon="%"
                      icon-after="true"
                      v-model="empresa.valorIr"
                      :key="reRenderButton"
                      @change="reRenderButton += 1"
                      :disabled="empresa.isenta_imposto"
                    ></vs-input>
                  </vs-col>
                  <vs-col
                    vs-w="3"
                    class="m-0"
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                  >
                    <vs-input
                      color="dark"
                      class="mx-2 w-full"
                      name="inss"
                      type="number"
                      min="0"
                      label="INSS"
                      icon="%"
                      icon-after="true"
                      v-model="empresa.valorInss"
                      :key="reRenderButton"
                      @change="reRenderButton += 1"
                      :disabled="empresa.isenta_imposto"
                    ></vs-input>
                  </vs-col>
                </vs-row>
              </vx-card>
            </vs-row>
          </div>
        </VuePerfectScrollbar>
      </div>
    </SideBar>
  </div>
</template>

<script>
import components from "@/components/default/exports.js";

export default {
  name: "ModalCadastroEmpresa",
  data() {
    return {
      empresa: {
        regime_especial_tributacao: 6,
        valorCofins: 0,
        valorPis: 0,
        valorInss: 0,
        valorIr: 0,
        valorCsll: 0
      },
      modalCadastroEmpresa: false,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.8,
        height: 1000
      },
      regime_especial_tributacao: [
        { id: 1, nome: "Microempresa Municipal" },
        { id: 2, nome: "Estimativa" },
        { id: 3, nome: "Sociedade de Profissionais" },
        { id: 4, nome: "Cooperativa" },
        { id: 5, nome: "Microempresário Individual (MEI)" },
        { id: 6, nome: "Microempresário e Empresa de Pequeno Porte (ME EPP" }
      ],
      reRenderButton: 0
    };
  },
  methods: {
    async submitForm() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.cadastrarEmpresa();
        } else {
          this.$vs.notify(this.$notify.Empty);
        }
      });
    },
    async cadastrarEmpresa() {
      await this.$vs.loading({
        container: "#loadingCadastroEmpresa",
        scale: 0.6
      });
      try {
        const res = await this.$http.post("empresa", this.empresa);
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: "cadastrar",
          setor: "comercial",
          ip: window.localStorage.getItem("ip"),
          texto: "Cadastro da empresa N°" + res.id
        };
        await this.$logger(logData);
        this.$vs.notify(this.$notify.Success);
        this.modalCadastroEmpresa = false;
        this.empresa.pop();
        this.empresa = {
          regime_especial_tributacao: 6,
          valorCofins: 0,
          valorPis: 0,
          valorInss: 0,
          valorIr: 0,
          valorCsll: 0
        };
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$emit("update");
        await this.$vs.loading.close(
          "#loadingCadastroEmpresa > .con-vs-loading"
        );
      }
    },
    async changeIsenta() {
      if (this.empresa.isenta_imposto == true) {
        this.empresa.valorCofins = 0;
        this.empresa.valorPis = 0;
        this.empresa.valorInss = 0;
        this.empresa.valorIr = 0;
        this.empresa.valorCsll = 0;
      }
      this.reRenderButton += 1;
    }
  },
  components: { ...components }
};
</script>

<style>
.scroll-cadastrar-empresa {
  max-height: 83vh !important;
}
</style>

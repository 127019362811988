<template>
  <div>
    <a href="#" @click="!$atividades.permissoes.includes(15) == false ? modalCertificadoEmpresa = true : 1">
        <feather-icon :style="!$atividades.permissoes.includes(15) == true ? 'color: lightgrey;': ''" icon="FileTextIcon" class="mr-2" />
    </a>
    <SideBar
      title="Certificado da empresa"
      @hide="cancelar"
      :disabled="!$atividades.permissoes.includes(15)"
      :active="modalCertificadoEmpresa"
      size="small"
      textSucces="Atualizar"
      @success="atualizarCertificado()"
    >
      <div slot="body">
        <VuePerfectScrollbar class="scroll-edit-certificado-empresa" :settings="settings">
          <div class="pr-3 w-full">
            <b-form-file
              :state="Boolean(file)"
              name="foto"
              class="mt-4"
              accept=".pfx"
              @change="uploadFunc($event)"
              placeholder="Atualizar certificado..."
              drop-placeholder="arraste aqui..."
            ></b-form-file>
          </div>
        </VuePerfectScrollbar>
      </div>
    </SideBar>
  </div>
</template>

<script>
import components from "@/components/default/exports.js";
import Datepicker from "vuejs-datepicker";
import moment from "moment";

export default {
  name: "modalCertificadoEmpresa",
  props: {cnpj: null},
  data() {
    return {
        file: null,
        modalCertificadoEmpresa: false,
        settings: {
            maxScrollbarLength: 60,
            wheelSpeed: 0.8,
            height: 1000
        },
    };
  },
  methods: {
    async uploadFunc($event) {
      let file = $event.srcElement.files[0];
      let reader = new FileReader();
      reader.onload = async function(fileEvent) {
        await window.localStorage.setItem(
          "CERT_EMP",
          fileEvent.target.result
        );
      };
      await reader.readAsDataURL(file);
    },
    async atualizarCertificado() {
      this.$vs.loading();
      let data = {}
      data.certificado = await window.localStorage.getItem("CERT_EMP");
      data.cnpj = await this.cnpj
      try {
        const res = await this.$http.post("updateCertificado", data);
        let logData = {
            id_colaborador: localStorage.getItem("id"),
            funcao: 'EDITAR',
            setor: 'ADMIN',
            ip: window.localStorage.getItem("ip"),
            texto: 'EDITAR CERITIFCADO DA EMPRESA' + res.cnpj}
        await this.$logger(logData)
        this.$vs.notify(this.$notify.Success);
        this.modalCertificadoEmpresa = false;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.$vs.loading.close();
      }
    },
    cancelar() {
      this.modalCertificadoEmpresa = false;
    }
  },
  components: { ...components, moment, Datepicker }
};
</script>

<style>
.scroll-edit-certificado-empresa {
  height: 70vh !important;
}
</style>
